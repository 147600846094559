import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#002e44", // Dark Blue
      light: "#76b5d0", // Light Blue from secondary palette
    },
    secondary: {
      main: "#b08e42", // Dark Gold
      light: "#d7b95c", // Light Gold
    },
    background: {
      default: "#001e2e", // Dark Navy
      paper: "#002e44", // Slightly lighter navy for cards/papers
    },
    text: {
      primary: "#ffffff", // White text for better contrast on dark background
      secondary: "#d7b95c", // Light Gold for secondary text
    },
    action: {
      active: "#d7b95c", // Light Gold for active elements
      disabled: "#b08e42", // Dark Gold for inactive elements
      hover: "rgba(215, 185, 92, 0.08)", // Light Gold tint for hover effect
    },
    success: {
      main: "#517e3a", // Green for success messages
    },
    error: {
      main: "#DC143C", // Red for error messages
    },
    warning: {
      main: "#E7BB20", // Orange for warning messages
    },
    custom: {
      report: "#E7BB20", // Yellow for report button
      block: "#DC143C", // Crimson for block button
    },
  },
  typography: {
    fontFamily: "Source Sans Pro, sans-serif",
    h1: {
      fontFamily: "Montserrat, sans-serif",
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
    },
    button: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20, // Rounded corners for buttons
          textTransform: "uppercase",
        },
        contained: {
          backgroundColor: "#d7b95c", // Light Gold for contained buttons
          color: "#002e44", // Dark Blue text for contrast
          "&:hover": {
            backgroundColor: "#b08e42", // Dark Gold on hover
          },
        },
        outlined: {
          borderColor: "#d7b95c", // Light Gold border for outlined buttons
          color: "#d7b95c", // Light Gold text
          "&:hover": {
            borderColor: "#b08e42", // Dark Gold border on hover
            backgroundColor: "rgba(176, 142, 66, 0.04)", // Slight Dark Gold tint on hover
          },
        },
        // Add new custom variant for report button
        reportOutlined: {
          color: "#E7BB20",
          borderColor: "#E7BB20",
          "&:hover": {
            borderColor: "#E7BB20",
            backgroundColor: "rgba(231, 187, 32, 0.04)",
          },
        },
        // Add new custom variant for block button
        blockOutlined: {
          color: "#DC143C",
          borderColor: "#DC143C",
          "&:hover": {
            borderColor: "#DC143C",
            backgroundColor: "rgba(220, 20, 60, 0.04)",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#002e44", // Slightly lighter navy for cards
          color: "#ffffff", // White text for cards
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#d7b95c", // Light gold for the active tab indicator
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#76b5d0", // Light blue for inactive tabs
          "&.Mui-selected": {
            color: "#d7b95c", // Light gold for active tab
          },
          "&:hover": {
            color: "#ffffff", // White on hover for better interaction feedback
            opacity: 1,
          },
          fontWeight: 600,
          fontSize: "0.9rem",
          textTransform: "uppercase",
        },
      },
    },
  },
});

export default theme;
