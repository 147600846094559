import React from "react";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

function SidebarUserInfo() {
  const { isAuthenticated, user } = useAuth();
  const theme = useTheme();
  if (!isAuthenticated || !user) {
    console.log("User not authenticated or not loaded");
    return null;
  }

  return (
    <ListItemButton
      component={RouterLink}
      to="/profile"
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 2,
        "&:hover": {
          backgroundColor: "action.hover", // Match the hover effect of other items
        },
      }}
    >
      <ListItemIcon>
        <Avatar
          alt={user?.firstname}
          src={
            user?.profilePicture ? user.profilePicture : "/default-avatar.jpg"
          }
          sx={{
            width: 40,
            height: 40,
            border: "2px solid white",
            boxShadow: 1,
            marginRight: 2,
          }}
        />
      </ListItemIcon>
      <ListItemText
        primary={user.firstname}
        primaryTypographyProps={{
          color: "white",
          noWrap: true,
        }}
      />
    </ListItemButton>
  );
}

export default SidebarUserInfo;
