// src/pages/Home.js

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { checkBackendStatus } from "../services/api";
import { useAuth } from "../hooks/useAuth"; // Import the useAuth hook

function Home() {
  const [backendStatus, setBackendStatus] = useState("checking");
  const { isAuthenticated } = useAuth(); // Use the useAuth hook
  const navigate = useNavigate();

  useEffect(() => {
    const checkStatus = async () => {
      try {
        await checkBackendStatus();
        setBackendStatus("online");
      } catch (error) {
        setBackendStatus("offline");
      }
    };

    if (process.env.NODE_ENV === "development") {
      checkStatus();
    }
  }, []);

  // // If user is authenticated, redirect to dashboard
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/dashboard");
  //   }
  // }, [isAuthenticated, navigate]);

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Box
        sx={{
          pt: 8,
          mt: 8,
          mb: 8,
          pb: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" component="h1" gutterBottom>
          Welcome to Social Parlay
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          The social betting platform for golf enthusiasts
        </Typography>
        {process.env.NODE_ENV === "development" && (
          <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="body1" sx={{ mr: 1 }}>
              Backend Status:
            </Typography>
            {backendStatus === "checking" && <CircularProgress size={20} />}
            {backendStatus === "online" && (
              <Typography color="success.main">Online</Typography>
            )}
            {backendStatus === "offline" && (
              <Typography color="error.main">Offline</Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Home;
