import React from "react";
import { Typography, Box, Container, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ForgotPasswordForm from "../../components/auth/ForgotPasswordForm";

function ForgotPassword() {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <ForgotPasswordForm />
        <Link component={RouterLink} to="/login" variant="body2">
          Remember your password? Sign In
        </Link>
      </Box>
    </Container>
  );
}

export default ForgotPassword;
