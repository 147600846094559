// src/services/playerService.js
import api from "./api";

const getPlayersData = async () => {
  try {
    const response = await api.get("/players");
    return response.data;
  } catch (error) {
    console.error("Error fetching players data:", error);
    throw error;
  }
};

export default getPlayersData;
