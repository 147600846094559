import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { reportUser, blockUser, unblockUser } from "../../services/userService";

function UserActions({ user, onUpdate }) {
  const [openReport, setOpenReport] = useState(false);
  const [reportReason, setReportReason] = useState("");

  const handleReport = async () => {
    try {
      await reportUser(user.id, reportReason);
      setOpenReport(false);
      setReportReason("");
      // You might want to show a success message here
    } catch (error) {
      console.error("Error reporting user:", error);
      // You might want to show an error message here
    }
  };

  const handleBlock = async () => {
    try {
      await blockUser(user.id);
      onUpdate();
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  const handleUnblock = async () => {
    try {
      await unblockUser(user.id);
      onUpdate();
    } catch (error) {
      console.error("Error unblocking user:", error);
    }
  };

  return (
    <>
      <Button variant="reportOutlined" onClick={() => setOpenReport(true)}>
        Report
      </Button>
      {user.isBlocked ? (
        <Button variant="blockOutlined" onClick={handleUnblock}>
          Unblock
        </Button>
      ) : (
        <Button variant="blockOutlined" onClick={handleBlock}>
          Block
        </Button>
      )}

      <Dialog open={openReport} onClose={() => setOpenReport(false)}>
        <DialogTitle>Report User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Reason for reporting"
            type="text"
            fullWidth
            value={reportReason}
            onChange={(e) => setReportReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReport(false)}>Cancel</Button>
          <Button onClick={handleReport}>Submit Report</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserActions;
