import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Avatar,
  Typography,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  Alert,
  Tabs,
  Tab,
} from "@mui/material";
import {
  getUserProfile,
  followUser,
  unfollowUser,
} from "../../services/userService";
import UserActions from "./UserActions";

function UserProfile() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    fetchUserProfile();
  }, [userId]);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const userData = await getUserProfile(userId);
      setUser(userData);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setError("Failed to load user profile");
    } finally {
      setLoading(false);
    }
  };

  const handleFollow = async () => {
    try {
      await followUser(userId);
      setUser({ ...user, isFollowing: true });
      setSnackbar({
        open: true,
        message: `You are now following ${user.firstname}`,
        severity: "success",
      });
    } catch (error) {
      console.error("Error following user:", error);
      setSnackbar({
        open: true,
        message: "Failed to follow user. Please try again.",
        severity: "error",
      });
    }
  };

  const handleUnfollow = async () => {
    try {
      await unfollowUser(userId);
      setUser({ ...user, isFollowing: false });
      setSnackbar({
        open: true,
        message: `You have unfollowed ${user.firstname}`,
        severity: "success",
      });
    } catch (error) {
      console.error("Error unfollowing user:", error);
      setSnackbar({
        open: true,
        message: "Failed to unfollow user. Please try again.",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const formatBalance = (balance) => {
    if (typeof balance === "number") {
      return balance.toFixed(2);
    } else if (typeof balance === "string") {
      const num = parseFloat(balance);
      return isNaN(num) ? "N/A" : num.toFixed(2);
    }
    return "N/A";
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!user) {
    return <Typography>User not found</Typography>;
  }

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "300px",
          overflow: "hidden",
        }}
      >
        <img
          src={
            user.coverPicture
              ? `${process.env.REACT_APP_API_URL}${user.coverPicture}`
              : "/default-cover.jpg"
          }
          alt="Cover"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>

      <Container maxWidth="md">
        <Box
          sx={{
            mt: -8,
            mb: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            alt={`${user.firstname} ${user.lastname}`}
            src={
              user.profilePicture
                ? `${process.env.REACT_APP_API_URL}${user.profilePicture}`
                : "/default-avatar.jpg"
            }
            sx={{
              width: 150,
              height: 150,
              border: "4px solid white",
              boxShadow: 1,
            }}
          />

          <Typography variant="h5" sx={{ mt: 2 }}>
            {user.firstname} {user.lastname}
          </Typography>

          <Box sx={{ mt: 2, mb: 2 }}>
            {user.isFollowing ? (
              <Button variant="outlined" onClick={handleUnfollow}>
                Unfollow
              </Button>
            ) : (
              <Button variant="contained" onClick={handleFollow}>
                Follow
              </Button>
            )}
            <UserActions user={user} onUpdate={fetchUserProfile} />
          </Box>

          <Box sx={{ display: "flex", mt: 2, mb: 2 }}>
            <Box sx={{ textAlign: "center", mr: 4 }}>
              <Typography variant="h6">218</Typography>
              <Typography variant="body2">POSTS</Typography>
            </Box>
            <Box sx={{ textAlign: "center", mr: 4 }}>
              <Typography variant="h6">98</Typography>
              <Typography variant="body2">FOLLOWERS</Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h6">6</Typography>
              <Typography variant="body2">FOLLOWING</Typography>
            </Box>
          </Box>
          <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
            <Tab label="Latest Activity" />
            <Tab label="Bet Statistics" />
            <Tab label="Earnings" />
            <Tab label="Groups" />
          </Tabs>
        </Box>
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default UserProfile;
