import api from "./api";

export const getUserData = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get("/user/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching current user:", error.response || error);
    throw error;
  }
};

export const uploadPicture = async (formData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post("/user/upload-picture", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading picture:", error);
    throw error;
  }
};

export const searchUsers = async (query) => {
  try {
    const response = await api.get(
      `/user/search?query=${encodeURIComponent(query)}`
    );
    return response.data;
  } catch (error) {
    console.error("Error searching users:", error);
    throw error;
  }
};

export const followUser = async (userId) => {
  try {
    const response = await api.post(`/user/follow/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error following user:", error);
    throw error;
  }
};

export const unfollowUser = async (userId) => {
  try {
    const response = await api.post(`/user/unfollow/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error unfollowing user:", error);
    throw error;
  }
};

export const reportUser = async (userId, reason) => {
  try {
    const response = await api.post(`/user/report/${userId}`, { reason });
    return response.data;
  } catch (error) {
    console.error("Error reporting user:", error);
    throw error;
  }
};

export const blockUser = async (userId) => {
  try {
    const response = await api.post(`/user/block/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error blocking user:", error);
    throw error;
  }
};

export const unblockUser = async (userId) => {
  try {
    const response = await api.post(`/user/unblock/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error unblocking user:", error);
    throw error;
  }
};

export const getUserProfile = async (userId) => {
  try {
    const response = await api.get(`/user/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user profile:", error);
    throw error;
  }
};

export const updateUserProfile = async (updatedData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.put("/user/update-profile", updatedData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};
