import React, { useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Typography, Box, Container, Link } from "@mui/material";
import LoginForm from "../../components/auth/LoginForm";

function Login({ onLogin }) {
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleLoginSuccess = () => {
    navigate("/dashboard");
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Login to Social Parlay
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <LoginForm
          onLogin={onLogin}
          onLoginSuccess={handleLoginSuccess}
          setError={setError}
        />
        <Link component={RouterLink} to="/forgot-password" variant="body2">
          Forgot password?
        </Link>
        <Link component={RouterLink} to="/register" variant="body2">
          Don't have an account? Sign Up
        </Link>
      </Box>
    </Container>
  );
}

export default Login;
