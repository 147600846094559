import React, { useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Typography, Box, Container, Alert, Link } from "@mui/material";
import ResetPasswordForm from "../../components/auth/ResetPasswordForm";

function ResetPassword() {
  const [success, setSuccess] = useState("");
  const history = useNavigate();

  const handleResetSuccess = (message) => {
    setSuccess(message);
    setTimeout(() => {
      history.push("/login");
    }, 3000);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Your Password
        </Typography>
        {success && <Alert severity="success">{success}</Alert>}
        <ResetPasswordForm onResetSuccess={handleResetSuccess} />
        <Link component={RouterLink} to="/login" variant="body2">
          Remember your password? Sign In
        </Link>
      </Box>
    </Container>
  );
}

export default ResetPassword;
