import React, { useState } from "react";
import { TextField, Button, Box, Alert } from "@mui/material";
import { verifyEmail, resendVerification } from "../../services/api";

function EmailVerificationForm({ email, onVerificationSuccess }) {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleVerify = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      await verifyEmail(email, otp);
      setSuccess("Email verified successfully. You can now log in.");
      onVerificationSuccess();
    } catch (error) {
      setError(error.response?.data?.message || "Verification failed");
    }
  };

  const handleResend = async () => {
    setError("");
    setSuccess("");

    try {
      await resendVerification(email);
      setSuccess("Verification email resent. Please check your inbox.");
    } catch (error) {
      setError(
        error.response?.data?.message || "Failed to resend verification email"
      );
    }
  };

  return (
    <Box sx={{ width: "100%", mt: 1 }}>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <Box component="form" onSubmit={handleVerify} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="otp"
          label="Verification Code"
          name="otp"
          autoFocus
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Verify Email
        </Button>
      </Box>
      <Button onClick={handleResend} fullWidth>
        Resend Verification Email
      </Button>
    </Box>
  );
}

export default EmailVerificationForm;
