import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Container } from "@mui/material";
import EmailVerificationForm from "../../components/auth/EmailVerificationForm";

function EmailVerification() {
  const [email, setEmail] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmail(params.get("email") || "");
  }, [location]);

  const handleVerificationSuccess = () => {
    setTimeout(() => navigate("/login"), 3000);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Verify Your Email
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
          Please enter the verification code sent to {email}
        </Typography>
        <EmailVerificationForm
          email={email}
          onVerificationSuccess={handleVerificationSuccess}
        />
      </Box>
    </Container>
  );
}

export default EmailVerification;
