import React from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Typography, Box, Container, Link } from "@mui/material";
import RegisterForm from "../../components/auth/RegisterForm";

function Register() {
  const navigate = useNavigate();

  const handleRegisterSuccess = (email) => {
    navigate(`/verify-email?email=${encodeURIComponent(email)}`);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Register for Social Parlay
        </Typography>
        <RegisterForm onRegisterSuccess={handleRegisterSuccess} />
        <Link component={RouterLink} to="/login" variant="body2">
          Already have an account? Sign in
        </Link>
      </Box>
    </Container>
  );
}

export default Register;
