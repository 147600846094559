// src/hooks/useAuth.js
import { useState, useEffect } from "react";
import { refreshToken } from "../services/api";
import { getUserData } from "../services/userService";

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          await refreshToken();
          setIsAuthenticated(true);
          const userData = await getUserData();
          setUser(userData);
        } catch (error) {
          localStorage.removeItem("token");
          setIsAuthenticated(false);
          setUser(null);
        }
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    };

    checkAuth();
  }, []);

  const login = async (token) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
    const userData = await getUserData();
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setUser(null);
  };

  return { isAuthenticated, user, login, logout };
}
