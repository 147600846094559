import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useAuth } from "./hooks/useAuth";
import Navbar from "./components/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./pages/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import EmailVerification from "./pages/auth/EmailVerification";
import Dashboard from "./pages/user/Dashboard";
import Profile from "./pages/user/Profile";
import UserProfile from "./components/users/UserProfile";
import PrivateRoute from "./components/PrivateRoute";
import Players from "./pages/players/Players";

function App() {
  const { isAuthenticated, login, logout } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Router>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Navbar toggleDrawer={toggleDrawer} />
        <Box sx={{ display: "flex", flex: 1 }}>
          <Sidebar
            isOpen={sidebarOpen}
            toggleDrawer={toggleDrawer}
            isAuthenticated={isAuthenticated}
            onLogout={logout}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login onLogin={login} />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/verify-email" element={<EmailVerification />} />
              <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/players" element={<Players />} />
              </Route>
              <Route path="/user/:userId" element={<UserProfile />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
