import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import getPlayersData from "../../services/playerService";

function Players() {
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const data = await getPlayersData();
        // Filter active players (as a safeguard)
        const activePlayers = data.players.filter((player) => player.isActive);
        setPlayers(activePlayers);
      } catch (error) {
        console.error("Error fetching players:", error);
        setError("Failed to fetch players. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Active PGA Tour Players
      </Typography>
      <Grid container spacing={3}>
        {players.map((player) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={player.id}>
            <Card>
              <CardMedia
                component="img"
                height="280"
                image={player.headshot || "/default-player-image.jpg"}
                alt={player.displayName}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {player.displayName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Country: {player.country}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Age: {player.playerBio?.age || "N/A"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Players;
