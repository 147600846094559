import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Avatar,
  Button,
  Tabs,
  Tab,
  Paper,
  Container,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EditIcon from "@mui/icons-material/Edit";

import {
  getUserData,
  uploadPicture,
  updateUserProfile,
} from "../../services/userService";
import { useAuth } from "../../hooks/useAuth";

function Profile() {
  const { isAuthenticated } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const [isEditing, setIsEditing] = useState(false);

  const [editableData, setEditableData] = useState({
    firstname: "",
    lastname: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!editableData.firstname.trim())
      newErrors.firstname = "First name is required";
    if (!editableData.lastname.trim())
      newErrors.lastname = "Last name is required";
    if (!editableData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(editableData.email)) {
      newErrors.email = "Email is invalid";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      setEditableData({
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
      });
    }
  }, [userData]);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      setError("");
      const response = await getUserData();
      console.log("Profile response:", response);
      setUserData(response);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError("Failed to fetch user data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handlePictureUpload = async (event, type) => {
    const file = event.target.files[0];
    if (!isAuthenticated) {
      setSnackbar({
        open: true,
        message: "Please log in to upload pictures",
        severity: "error",
      });
      return;
    }

    if (!file) return;

    // File type and size validation
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (!allowedTypes.includes(file.type)) {
      setSnackbar({
        open: true,
        message: "Invalid file type. Please upload a JPEG, PNG, or GIF.",
        severity: "error",
      });
      return;
    }

    if (file.size > maxSize) {
      setSnackbar({
        open: true,
        message: "File is too large. Maximum size is 5MB.",
        severity: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("picture", file);
    formData.append("type", type);

    setUploading(true);

    try {
      const response = await uploadPicture(formData);
      console.log("Upload response:", response);
      const pictureField =
        type === "profile" ? "profilePicture" : "coverPicture";
      if (response && response[pictureField]) {
        setUserData((prevData) => ({
          ...prevData,
          [pictureField]: response[pictureField],
        }));
        setSnackbar({
          open: true,
          message: "Picture uploaded successfully",
          severity: "success",
        });
      } else {
        console.error("Invalid response from server:", response);
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error uploading picture:", error);
      setSnackbar({
        open: true,
        message: "Failed to upload image. Please try again.",
        severity: "error",
      });
    } finally {
      setUploading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  const formatBalance = (balance) => {
    if (typeof balance === "number") {
      return balance.toFixed(2);
    } else if (typeof balance === "string") {
      const num = parseFloat(balance);
      return isNaN(num) ? "N/A" : num.toFixed(2);
    }
    return "N/A";
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      setUploading(true);
      const updatedUser = await updateUserProfile(editableData);
      setUserData(updatedUser);
      setIsEditing(false);
      setSnackbar({
        open: true,
        message: "Profile updated successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      setSnackbar({
        open: true,
        message: "Failed to update profile. Please try again.",
        severity: "error",
      });
    } finally {
      setUploading(false);
    }
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  if (!isAuthenticated) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6">Please log in to view your profile</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "300px",
          overflow: "hidden",
        }}
      >
        <img
          src={
            userData?.coverPicture
              ? userData.coverPicture
              : "/default_cover.jpg"
          }
          alt="Cover"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        <IconButton
          sx={{
            position: "absolute",
            bottom: 10,
            right: 10,
            bgcolor: "background.paper",
          }}
          component="label"
          disabled={uploading}
        >
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={(e) => handlePictureUpload(e, "cover")}
          />
          {uploading ? <CircularProgress size={24} /> : <CameraAltIcon />}
        </IconButton>
      </Box>

      <Container maxWidth="lg">
        <Box
          sx={{
            mt: -8,
            mb: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              mb: 4,
              display: "flex",
              justifyContent: "center",
              // transform: "translateY(-50%)",
            }}
          >
            <Avatar
              alt={userData?.firstname}
              src={
                userData?.profilePicture
                  ? userData.profilePicture
                  : "/default-avatar.jpg"
              }
              sx={{
                width: 150,
                height: 150,
                border: "4px solid white",
                boxShadow: 1,
              }}
            />
            <IconButton
              sx={{
                position: "absolute",
                bottom: 0,
                right: "50%",
                transform: "translateX(75px)",
                bgcolor: "background.paper",
              }}
              component="label"
              disabled={uploading}
            >
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => handlePictureUpload(e, "profile")}
              />
              {uploading ? <CircularProgress size={24} /> : <CameraAltIcon />}
            </IconButton>
          </Box>
          {isEditing ? (
            <>
              <TextField
                name="firstname"
                label="First Name"
                value={editableData.firstname}
                onChange={handleInputChange}
                error={!!errors.firstname}
                helperText={errors.firstname}
                sx={{ mt: 2 }}
              />
              <TextField
                name="lastname"
                label="Last Name"
                value={editableData.lastname}
                onChange={handleInputChange}
                error={!!errors.lastname}
                helperText={errors.lastname}
                sx={{ mt: 2 }}
              />
              <TextField
                name="email"
                label="Email"
                value={editableData.email}
                onChange={handleInputChange}
                error={!!errors.email}
                helperText={errors.email}
                sx={{ mt: 2 }}
              />
            </>
          ) : (
            <>
              <Typography variant="h5" sx={{ mt: 2 }}>
                {userData?.firstname} {userData?.lastname}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {userData?.email}
              </Typography>
            </>
          )}
          {isEditing && (
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              disabled={uploading}
            >
              {uploading ? "Saving..." : "Save Changes"}
            </Button>
          )}
          <Button
            startIcon={<EditIcon />}
            onClick={() => setIsEditing(!isEditing)}
            sx={{ mt: 2 }}
          >
            {isEditing ? "Cancel Edit" : "Edit Profile"}
          </Button>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Balance: ${formatBalance(userData?.balance)}
          </Typography>
          <Box sx={{ display: "flex", mt: 2, mb: 2 }}>
            <Box sx={{ textAlign: "center", mr: 4 }}>
              {/* Add the correct numbers here along with clickable link to list of followers */}
              <Typography variant="h6">218</Typography>
              <Typography variant="body2">POSTS</Typography>
            </Box>
            <Box sx={{ textAlign: "center", mr: 4 }}>
              <Typography variant="h6">98</Typography>
              <Typography variant="body2">FOLLOWERS</Typography>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h6">6</Typography>
              <Typography variant="body2">FOLLOWING</Typography>
            </Box>
          </Box>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              mb: 2,
              "& .MuiTabs-indicator": {
                height: 3, // Adjust the height of the indicator
              },
            }}
          >
            <Tab label="Activity" />
            <Tab label="Statistics" />
            <Tab label="Earnings" />
            <Tab label="Groups" />
          </Tabs>
        </Box>
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Profile;
