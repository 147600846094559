// src/components/Sidebar.js
import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import LogoutDialog from "../auth/LogoutDialog";
import SidebarUserInfo from "./SidebarUserInfo";

const Sidebar = ({ isOpen, toggleDrawer, isAuthenticated, onLogout }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  useEffect(() => {
    console.log("Is authenticated:", isAuthenticated);
  }, [isAuthenticated]);

  const menuItems = [
    { text: "Home", icon: <HomeIcon />, link: "/" },
    {
      text: "Dashboard",
      icon: <DashboardIcon />,
      link: "/dashboard",
      auth: true,
    },
    // <Link to="/players">Players</Link>
    {
      text: "Players",
      icon: <PeopleIcon />,
      link: "/players",
    },
    {
      text: "Login",
      icon: <LoginIcon />,
      link: "/login",
      auth: true,
      hideWhenAuth: true,
    },
  ];

  const handleLogoutClick = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutConfirm = () => {
    setLogoutDialogOpen(false);
    onLogout();
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };

  const drawerContent = (
    <List>
      {isAuthenticated && <SidebarUserInfo />}
      {menuItems.map((item) => {
        if (
          (item.authRequired && !isAuthenticated) ||
          (item.hideWhenAuth && isAuthenticated)
        ) {
          return null;
        }
        return (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={item.link ? RouterLink : undefined}
              to={item.link}
              onClick={isLargeScreen ? undefined : toggleDrawer}
              sx={{
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
            >
              <ListItemIcon sx={{ color: "secondary.light" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} sx={{ color: "white" }} />
            </ListItemButton>
          </ListItem>
        );
      })}
      {isAuthenticated && (
        <ListItem disablePadding>
          <ListItemButton
            onClick={handleLogoutClick}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              },
            }}
          >
            <ListItemIcon sx={{ color: "secondary.light" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" sx={{ color: "white" }} />
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );

  const drawerSx = {
    width: 240,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 240,
      boxSizing: "border-box",
      backgroundColor: theme.palette.primary.main, // Use the primary color for background
      color: "white", // Set text color to white for better contrast
    },
  };

  return (
    <>
      <SidebarUserInfo />
      {isLargeScreen ? (
        <Drawer
          variant="permanent"
          sx={{
            ...drawerSx,
            "& .MuiDrawer-paper": {
              ...drawerSx["& .MuiDrawer-paper"],
              top: 64, // Height of the AppBar
              height: "calc(100% - 64px)",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={toggleDrawer}
          sx={drawerSx}
        >
          {drawerContent}
        </Drawer>
      )}
      <>
        <LogoutDialog
          open={logoutDialogOpen}
          onConfirm={handleLogoutConfirm}
          onCancel={handleLogoutCancel}
        />
      </>
    </>
  );
};

export default Sidebar;
