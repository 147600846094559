import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  ListItem,
  Typography,
  Avatar,
  Button,
  Box,
  Paper,
  ClickAwayListener,
  InputAdornment,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { FixedSizeList } from "react-window";
import {
  searchUsers,
  followUser,
  unfollowUser,
} from "../../services/userService";
import UserActions from "./UserActions";

function UserSearch() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState(null);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleSearch = async () => {
      if (query.length > 2) {
        setIsSearching(true);
        try {
          const users = await searchUsers(query);
          setResults(users);
        } catch (error) {
          console.error("Error searching users:", error);
          setError("An error occurred while searching. Please try again.");
        } finally {
          setIsSearching(false);
        }
      } else {
        setResults([]);
      }
    };

    const debounceTimer = setTimeout(handleSearch, 300);
    return () => clearTimeout(debounceTimer);
  }, [query]);

  const handleFollow = async (userId) => {
    try {
      await followUser(userId);
      setResults(
        results.map((user) =>
          user.id === userId ? { ...user, isFollowing: true } : user
        )
      );
    } catch (error) {
      console.error("Error following user:", error);
      setError("An error occurred while following the user. Please try again.");
    }
  };

  const handleUnfollow = async (userId) => {
    try {
      await unfollowUser(userId);
      setResults(
        results.map((user) =>
          user.id === userId ? { ...user, isFollowing: false } : user
        )
      );
    } catch (error) {
      console.error("Error unfollowing user:", error);
      setError(
        "An error occurred while unfollowing the user. Please try again."
      );
    }
  };

  const handleUserUpdate = (updatedUser) => {
    setResults(
      results.map((user) =>
        user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      )
    );
  };

  const handleClickAway = () => {
    setResults([]);
    setQuery("");
  };

  const clearSearch = () => {
    setQuery("");
    setResults([]);
  };

  const ResultItem = ({ index, style }) => {
    const user = results[index];
    return (
      <ListItem
        style={style}
        key={user.id}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 16px",
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: "action.hover",
            cursor: "pointer",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
          <Avatar
            src={
              user.profilePicture
                ? `${process.env.REACT_APP_API_URL}${user.profilePicture}`
                : "/default-avatar.jpg"
            }
            alt={`${user.firstname} ${user.lastname}`}
            sx={{ marginRight: 2 }}
          />
          <Link
            to={`/user/${user.id}`}
            style={{ textDecoration: "none", color: "inherit", flexGrow: 1 }}
            onClick={clearSearch}
          >
            <Typography>{`${user.firstname} ${user.lastname}`}</Typography>
          </Link>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {user.isFollowing ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleUnfollow(user.id);
              }}
              variant="outlined"
              size="small"
            >
              Unfollow
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleFollow(user.id);
              }}
              variant="contained"
              size="small"
            >
              Follow
            </Button>
          )}
          <UserActions
            user={user}
            onUpdate={(updatedUser) => handleUserUpdate(updatedUser)}
          />
        </Box>
      </ListItem>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ position: "relative", width: "400px" }} ref={searchRef}>
        <TextField
          placeholder="Search Users"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "secondary.light",
              },
              "&:hover fieldset": {
                borderColor: "secondary.main",
              },
              "&.Mui-focused fieldset": {
                borderColor: "primary.main",
              },
            },
            "& .MuiInputBase-input": {
              color: "text.primary",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="secondary" />
              </InputAdornment>
            ),
          }}
        />
        {query.length > 2 && (
          <Paper
            elevation={3}
            sx={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              zIndex: 1200,
              maxHeight: "400px",
              overflowY: "auto",
              mt: 1,
              bgcolor: "background.paper",
            }}
          >
            {isSearching ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                <CircularProgress size={24} color="secondary" />
              </Box>
            ) : results.length > 0 ? (
              <FixedSizeList
                height={400}
                width="100%"
                itemSize={72}
                itemCount={results.length}
                overscanCount={5}
              >
                {ResultItem}
              </FixedSizeList>
            ) : (
              <Box sx={{ p: 2, textAlign: "center", color: "text.secondary" }}>
                No users found
              </Box>
            )}
          </Paper>
        )}
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </ClickAwayListener>
  );
}

export default UserSearch;
