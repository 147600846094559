// src/components/Navbar.js
import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UserSearch from "./users/UserSearch";

const Navbar = ({ toggleDrawer }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        {!isLargeScreen && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            padding: "8px 0",
          }}
        >
          <img
            src="/logo.png"
            alt="Social Parlay Logo"
            style={{ height: "48px", marginRight: "16px" }}
          />
        </Box>
        <UserSearch />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
